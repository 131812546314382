import config from '../config';
import request, { requestOptions } from '../utils/request';

const baseUrl = `${config.API_ROOT}/app/crawling`;

type GenericResponse = {
  err?: boolean;
  data?: {
    ok: boolean;
  }
}

const Crawling = {
  async newCrawlingEvent({ url, companyId }: { url: string; companyId: string }) {
    const options = requestOptions('POST', { url, companyId }, true);
    const res = await request(baseUrl, options);
    return res as GenericResponse;
  },
  async skipCrawling({ companyId }: { companyId: string }) {
    const options = requestOptions('POST', { companyId }, true);
    const res = await request(`${baseUrl}/skip`, options);
    return res as GenericResponse;
  }
}

export default Crawling;
