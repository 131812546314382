import { parseDateTimeZone } from "./moment";

import { AsYouType } from "libphonenumber-js";

export const adaptCampaigns = (campaigns) =>
  campaigns.map((c) => ({
    id: c.id,
    name: c.name,
    template: c.template,
    timestamp: parseDateTimeZone(c.createAt, "DD/MM/YYYY HH:mm"),
  }));

export const adaptLeads = (leads) =>
  leads.map((l) => ({
    id: l.id,
    name: l.name,
    phoneNumber: new AsYouType().input(`+${l.phoneNumber}`),
    country: l.country,
    externalId: l.externalId,
    timestamp: parseDateTimeZone(l.createAt, "DD/MM/YYYY HH:mm"),
  }));
