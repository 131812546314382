import { useState } from "react";
import {
  IonHeader,
  IonToolbar,
  isPlatform,
  IonPage,
  IonContent,
  IonTitle,
  IonBreadcrumbs,
  IonBreadcrumb,
  useIonToast,
} from "@ionic/react";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "@tanstack/react-query";

// Components
import PaginationComponent from "../../common/pagination";
import Leads from "./list";

// Styles
import headerStyles from "../../../theme/header.module.css";
import styles from "./styles.module.css";

// API
import CrmApi from "../../../api/crm";

// Other
const isMobile = isPlatform("android") || isPlatform("ios");

const Header = () => {
  return (
    <div>
      <h1 className={headerStyles.pageTitle}>
        <FormattedMessage id="leads.pageTitle" />
      </h1>
      <h3 className={headerStyles.pageDescription}>
        <FormattedMessage id="leads.pageDescription" />
      </h3>
    </div>
  );
};

const MobileHeader = () => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id="leads.pageTitle" />
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="leads.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const MobileTitle = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          <FormattedMessage id="leads.home" />
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

const CrmLeads: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const intl = useIntl();
  const [present] = useIonToast();

  const {
    isLoading: isLoadingLeads,
    data: leadsData,
    //refetch: refetchLeads,
  } = useQuery({
    queryFn: () => CrmApi.getLeads({ page: currentPage }),
    queryKey: ["getCrmLeads", currentPage],
    refetchOnWindowFocus: false,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleDownloadLeads = async () => {
    setDownloading(true);
    try {
      const { err, data } = await CrmApi.downloadLeads();
      setDownloading(false);
      if (err) {
        if (err.error) {
          return present({
            message: err.error?.[intl.locale],
            duration: 5500,
            color: "light",
            position: "top",
          });
        }
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5000,
          color: "light",
          position: "top",
        });
      }

      console.log(err);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "leads.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setDownloading(false);
      console.error("Error downloading leads:", error);
    }
  };

  const handleUpload = async () => {
    console.log(file);
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("files", file);
    setUploading(true);
    try {
      const { err, data } = await CrmApi.uploadLeads(formData);
      setUploading(false);
      if (err) {
        return present({
          message: err.error
            ? err.error?.[intl.locale]
            : intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5500,
          color: "light",
          position: "top",
        });
      }
      if (data) {
        return present({
          message: intl.formatMessage({ id: "leads.successUploadLeads" }),
          duration: 5500,
          color: "light",
          position: "top",
        });
      }
    } catch (err) {
      setUploading(false);
      console.error(err);
    } finally {
      setUploading(false);
    }
  };

  console.log(isLoadingLeads, leadsData);

  return (
    <IonPage id="main-content">
      {isMobile && <MobileTitle />}
      <IonContent
        {...(isMobile
          ? { fullscreen: true, color: "light", className: "ion-padding" }
          : {})}
      >
        <div className={isMobile ? "view-wrapper" : "page-wrapper container"}>
          {isMobile ? (
            <>
              <div className="view-pane">
                <MobileHeader />

                <Leads
                  isMobile={isMobile}
                  loading={isLoadingLeads}
                  list={leadsData?.leads}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.breadcrumbContainer}>
                <IonBreadcrumbs mode="ios">
                  <IonBreadcrumb
                    routerLink="/crm"
                    className="breadcrumb-parent"
                  >
                    <FormattedMessage id="nav.crm" />
                  </IonBreadcrumb>
                  <IonBreadcrumb routerLink={`/outbound`}>
                    <FormattedMessage id="crm.leads" />
                  </IonBreadcrumb>
                </IonBreadcrumbs>
              </div>
              <Header />

              <div className="mb-4">
                <div className="mb-2">
                  <button
                    className="btn btn-visito-primary mr-1"
                    onClick={handleDownloadLeads}
                    disabled={downloading}
                  >
                    {downloading ? (
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">
                          <FormattedMessage id="common.loading" />
                          ...
                        </span>
                      </Spinner>
                    ) : (
                      <FormattedMessage id="crm.downloadLeads" />
                    )}
                  </button>
                </div>
                <div>
                  <div>
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                    />
                  </div>
                  <button
                    className="btn btn-visito-primary"
                    disabled={!file || uploading}
                    onClick={handleUpload}
                  >
                    <FormattedMessage id="crm.uploadLeads" />
                  </button>
                </div>
              </div>
              <Leads
                isMobile={isMobile}
                loading={isLoadingLeads}
                list={leadsData?.leads}
              />
              {leadsData && leadsData?.pagination?.totalPages > 1 && (
                <PaginationComponent
                  currentPage={leadsData?.pagination.currentPage}
                  totalPages={leadsData?.pagination?.totalPages}
                  onChangePage={(page) => setCurrentPage(page)}
                />
              )}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {}

export default CrmLeads;
