import React from "react";
import { IonModal, useIonToast } from "@ionic/react";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { FormattedMessage, useIntl } from "react-intl";

type CrawlingCompletedProps = {
  onOk: () => void;
}

function CrawlingCompleted({ onOk }: CrawlingCompletedProps) {
  const [present] = useIonToast();
  const intl = useIntl();

  const onBeforeOk = () => {
    present({
      message: intl.formatMessage({ id: "aiAgentCreated" }),
      duration: 4500,
      color: "light",
      position: "top",
      cssClass: "success-toast",
    });

    return onOk()
  }

  return (
    <IonModal
      isOpen
      canDismiss={false}
      id="crawling-completed-modal"
    >
      <div className="flex flex-column gap-4 p-4 align-items-center justify-content-center">
        <IconCircleCheckFilled size={52} style={{ color: "var(--visito-color-1)" }} />
        <div className="flex flex-column gap-1 w-100 align-items-center justify-content-center">
          <h2 className="fs-4 mb-0 text-center">
            <FormattedMessage id="aiCrawlingSuccess" />
          </h2>
          <p className="description mb-0 text-center">
            <FormattedMessage id="aiCrawlingSuccessDesc" />
          </p>
        </div>
        <button
          className="btn btn-visito-primary w-100"
          onClick={onBeforeOk}
        >
          <FormattedMessage id="aiReview" />
        </button>
      </div>
    </IonModal>
  )
}

export default CrawlingCompleted;
