import { FormattedMessage } from "react-intl";

import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonModal,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
  IonNote,
} from "@ionic/react";

import styles from "../styles.module.css";
import { Integration } from "../../../interfaces";

interface Properties {
  isOpen: boolean;
  onWillDismiss: () => void;
  integration: Integration;
  toggle: Function;
  onReconnect: Function;
  isMobile: boolean;
}

const IntegrationDetails = ({
  onWillDismiss,
  isOpen,
  integration,
  toggle,
  onReconnect,
  isMobile,
}: Properties) => {
  const { cssLogo, key, accounts } = integration;
  return (
    <IonModal isOpen={isOpen} onWillDismiss={onWillDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {isMobile ? (
              <FormattedMessage id={`integrations.${key}Name`} />
            ) : (
              <div className={styles.titleDisplay}>
                <div className={`${styles.titleItem} ${styles.logo}`}>
                  <div className={`${styles.logo} ${cssLogo}`}></div>
                </div>

                <h3 className="fs-3">
                  <FormattedMessage id={`integrations.${key}Name`} />
                </h3>
              </div>
            )}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onWillDismiss()}>
              <FormattedMessage id="common.close" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={isMobile ? "view-wrapper" : ""}>
          <IonList inset={true}>
            {accounts.map((connection) => (
              <IonItem key={connection.name}>
                <IonToggle
                  {...(isMobile
                    ? { className: "visito-toggle" }
                    : { mode: "ios", className: "visito-toggle ios" })}
                  checked={connection.active}
                  onIonChange={(event: CustomEvent<{ checked: boolean }>) =>
                    toggle({
                      connection,
                      action: { active: event.detail.checked },
                    })
                  }
                >
                  <IonLabel>
                    <div className="fs-4 fw-semibold color-step-850">
                      {connection.name}
                    </div>
                  </IonLabel>
                  <IonNote>
                    <div className="fs-3 color-step-500">
                      {(() => {
                        switch (integration.type) {
                          case "channel":
                            return connection.active ? (
                              <FormattedMessage id="integrations.autoReplies" />
                            ) : (
                              <FormattedMessage id="integrations.manualReplies" />
                            );
                          case "location":
                            return connection.active ? (
                              <FormattedMessage id="integrations.pmsActive" />
                            ) : (
                              <FormattedMessage id="integrations.pmsPaused" />
                            );
                          default:
                            return null;
                        }
                      })()}
                    </div>
                  </IonNote>
                </IonToggle>
              </IonItem>
            ))}
            <IonItem onClick={() => onReconnect()} button={true}>
              <IonLabel>
                <div className="fs-3 color-step-500 color-visito-1 underline">
                  <FormattedMessage id={`integrations.${key}Reconnect`} />
                </div>
              </IonLabel>
            </IonItem>
          </IonList>
          <IonList inset={true}></IonList>
        </div>
      </IonContent>
    </IonModal>
  );
};
export default IntegrationDetails;
