import { useState, useEffect } from "react";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
} from "@ionic/react";
import { useQuery } from "@tanstack/react-query";

import SkeletonText from "../../../components/SkeletonText";

// Api
import AiApi from "../../../api/ai";

import AiTextContext from "../../../components/ai/text-context";

type EmbeddingsInitialState = {
  context?: string;
  embeddingId?: string;
}

function AiEmbeddings() {
  const [embedding, setEmbeddings] = useState<EmbeddingsInitialState>({
    context: undefined,
    embeddingId: undefined,
  });

  const {
    data: { data: aiData } = {},
    isLoading: isGettingAi,
    isRefetching: isRefetchingAi,
  } = useQuery({
    queryFn: () => AiApi.getAiSettings(),
    queryKey: ["getAiSettings"],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (aiData?.aiSettings?.embeddings && !embedding?.context) {
      const oldContext = aiData.aiSettings?.context?.general?.text;

      const { embeddings } = aiData.aiSettings;
      const context = embeddings?.find((emb: any) => emb?.type === "text");

      setEmbeddings((prevState) => ({
        ...prevState,
        context: oldContext || context?.embededContent,
        embeddingId: context?._id,
      }));
    }
  }, [aiData]);

  const loading = isGettingAi || isRefetchingAi;

  return (
    <IonPage id="main-content">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/ai-settings">
              {" "}
              AI Settings
            </IonBackButton>
          </IonButtons>
          <IonTitle>Text</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div>
          {loading && (
            <div className="h-75 w-100 flex flex-column p-4">
              <SkeletonText rows={17} />
            </div>
         )}
         {aiData?.aiSettings?.context?.general?.limit && embedding?.context && (
            <AiTextContext
              limit={aiData?.aiSettings?.context?.general?.limit || 0}
              text={embedding?.context || ""}
              embeddingId={embedding?.embeddingId}
              isMobile
            />
         )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AiEmbeddings;
