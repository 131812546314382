import React, { useState, useEffect, useRef } from "react";

import FilterSvg from "../../../theme/images/filter.svg";
import { FormattedMessage } from "react-intl";

// const CheckboxGroup = Checkbox.Group;
import styles from "./styles.module.css";
import { Company, Filter, Contact } from "../../../interfaces";
interface State {
  dropdownStatus: boolean;
}

interface Option {
  id: string;
}

const DropdownCheckbox: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [state, setState] = useState<State>({
    dropdownStatus: false,
  });
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setState((prevState) => ({ ...prevState, dropdownStatus: false }));
    }
  };

  const onToggle = () => {
    setState({ ...state, dropdownStatus: !state.dropdownStatus });
  };

  return (
    <div ref={dropdownRef}>
      <div className={styles.btnNavigation}>
        <button
          className={`btn btn-xs btn-ionic-light btn-flex ${styles.btnHeader}`}
          onClick={() => onToggle()}
        >
          <div className={styles.btnImageFilter}>
            <img src={FilterSvg} />
          </div>
          <div>
            <div className="d-flex">
              <FormattedMessage id={`chats.filter`} />
              {props.filters.simple.length || props.filters.companies.length ? (
                <div style={{ marginLeft: "3px" }}>
                  <div className="badge color-visito-1-bg">
                    {props.filters.simple.length +
                      props.filters.companies.length}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </button>{" "}
      </div>

      <div
        className={styles.dropdownContainer}
        style={{ display: state.dropdownStatus ? "inherit" : "none" }}
      >
        <div className={styles.dropdownBody}>
          {props.options.map((o: { id: string }) => (
            <div className="form-check" key={o.id}>
              <input
                className="form-check-input primary"
                type="checkbox"
                onChange={(e) =>
                  props.onFilterChange({
                    type: "simple",
                    field: e.target.id,
                    checked: e.target.checked,
                  })
                }
                id={o.id}
                checked={props.filters.simple.includes(o.id as keyof Contact)}
              />
              <label
                className="form-check-label fs-2 fw-semibold-1 pointer"
                htmlFor={o.id}
              >
                <FormattedMessage id={`chats.${o.id}`} />
              </label>
            </div>
          ))}
        </div>
        {props.companies.length > 1 ? (
          <>
            <div className={styles.dropdownTitle}>
              <span className="color-step-800 fs-2">
                <FormattedMessage id={`chats.accounts`} />
              </span>
            </div>

            <div className={styles.dropdownBody}>
              {props.companies.map((c: Company) => (
                <div className="form-check" key={c.id}>
                  <input
                    className="form-check-input primary"
                    type="checkbox"
                    id={c.id}
                    onChange={(e) =>
                      props.onFilterChange({
                        type: "companies",
                        field: e.target.id,
                        checked: e.target.checked,
                      })
                    }
                    checked={props.filters.companies.includes(c.id)}
                  />
                  <label
                    className={`${styles.dropdownLabel} form-check-label fs-2 fw-semibold-1 pointer label-company`}
                    htmlFor={c.id}
                  >
                    {c.name}
                  </label>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
interface ILayoutProps {
  options: Option[];
  onFilterChange: Function;
  filters: Filter;
  companies: Company[];
}
export default DropdownCheckbox;
