import config from "../config";
import request, { requestOptions } from "../utils/request";

const baseUrl = `${config.API_ROOT}/account`;

const Account = {
  lisInvites() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/list-invites`;
    return request(url, options);
  },
  getInvite(token) {
    const options = requestOptions("POST", { token }, true);
    const url = `${baseUrl}/get-invite`;
    return request(url, options);
  },
  acceptInvite(id) {
    const options = requestOptions("POST", { id }, true);
    const url = `${baseUrl}/accept-invite`;
    return request(url, options);
  },
};

export default Account;
