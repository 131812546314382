import { createReducer } from "@reduxjs/toolkit";
import { setRequest } from "../actions/requests";

import { Request } from "../interfaces";

const request: Request = {
  contactId: "",
};

export const Requests = createReducer(
  { request },
  {
    [setRequest.type]: (state, action) => ({
      ...state,
      request: {
        contactId: action.payload.request.contactId,
        filter: action.payload.request.filter,
      },
    }),
  }
);
