import config from "../config";
import request, { requestOptions } from "../utils/request";

const baseUrl = `${config.API_ROOT}/integrations`;

const Cloudbeds = {
  cloudbedsCode(code) {
    const options = requestOptions("POST", { code }, true);
    const url = `${baseUrl}/cloudbeds`;
    return request(url, options);
  },
  cloudbedsPreviewCode(code) {
    const options = requestOptions("POST", { code });
    const url = `${baseUrl}/cloudbeds-preview`;
    return request(url, options);
  },
  cloudbedsOnboarding(code) {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/cloudbeds-onboarding`;
    return request(url, options);
  },
  getIntegrations() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/`;
    return request(url, options);
  },
  list() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/list`;
    return request(url, options);
  },
  toggle({ connection, integration, action }) {
    const options = requestOptions(
      "PUT",
      { connection, integration, action },
      true
    );
    const url = `${baseUrl}/toggle-connection`;
    return request(url, options);
  },
  connectMeta({ meta }) {
    const options = requestOptions("POST", { meta }, true);
    const url = `${baseUrl}/connect-meta`;
    return request(url, options);
  },
  addDbIntegration({ integration, form }) {
    const options = requestOptions("POST", { integration, form }, true);
    const url = `${baseUrl}/add-db-integration`;
    return request(url, options);
  },
};

export default Cloudbeds;
