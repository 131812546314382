import config from "../config";
import request, { requestOptions } from "../utils/request";

const baseUrl = `${config.API_ROOT}/auth`;

const Auth = {
  login(loginData: object) {
    console.log(`${baseUrl}/login`);
    const options = requestOptions("POST", loginData, false);
    const url = `${baseUrl}/login`;
    return request(url, options);
  },

  register(userData: object) {
    const options = requestOptions("POST", userData, false);
    const url = `${baseUrl}/register`;
    return request(url, options);
  },
  registerUser(registerData: object) {
    const options = requestOptions("POST", registerData, false);
    const url = `${baseUrl}/register-user`;
    return request(url, options);
  },
  getRegisterUserInfoToken(token: string) {
    const options = requestOptions("POST", { token }, false);
    const url = `${baseUrl}/register-user-token-info`;
    return request(url, options);
  },
  me() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/me`;
    return request(url, options);
  },
  push(pushData: object) {
    const options = requestOptions("POST", pushData, true);
    const url = `${baseUrl}/push`;
    return request(url, options);
  },
  resetPush(pushData: object) {
    const options = requestOptions("POST", pushData, true);
    const url = `${baseUrl}/push-reset`;
    return request(url, options);
  },
  language(language: object) {
    const options = requestOptions("POST", language, true);
    const url = `${baseUrl}/language`;
    return request(url, options);
  },

  forgotPassword(forgotData: object) {
    const options = requestOptions("POST", forgotData, false);
    const url = `${baseUrl}/forgot-password`;
    return request(url, options);
  },
  resetPassword(resetData: object) {
    const options = requestOptions("POST", resetData, false);
    const url = `${baseUrl}/reset-password`;
    return request(url, options);
  },
  logout(logOutData: object) {
    const options = requestOptions("POST", logOutData, true);
    const url = `${baseUrl}/logout`;
    return request(url, options);
  },
  switchCompany(companyData: object) {
    const options = requestOptions("POST", companyData, true);
    const url = `${baseUrl}/switch-company`;
    return request(url, options);
  },
};

export default Auth;
