import { IconUserCircle, IconSettings } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./SettingsNavigation.css";
import { FormattedMessage } from "react-intl";
const SettingsNavigation: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { path } = props;
  return (
    <div className="settings-navigation-container">
      <h1>
        <FormattedMessage id="settings.pageTitle" />
      </h1>
      <div className="settings-navigation">
        <div className="section-title">
          <IconSettings size={17} style={{ marginTop: "2px" }} />
          <div>
            <FormattedMessage id="settings.settingsTitle" />
          </div>
        </div>
        <ul>
          <li
            className={classNames("", {
              selected: path === "/settings",
            })}
          >
            <Link className="link" to="/settings">
              <FormattedMessage id="settings.teams" />
            </Link>{" "}
          </li>

          <li
            className={classNames("", {
              selected: path === "/settings/payment",
            })}
          >
            <Link className="link" to="/settings/payment">
              <FormattedMessage id="settings.payment" />
            </Link>{" "}
          </li>
        </ul>
      </div>

      <div className="settings-navigation">
        <div className="section-title">
          <IconUserCircle size={17} />
          <div>
            <FormattedMessage id="settings.accountTitle" />
          </div>
        </div>
        <ul>
          <li
            className={classNames("", {
              selected: path === "/settings/language",
            })}
          >
            <Link className="link" to="/settings/language">
              <FormattedMessage id="settings.language" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

interface ILayoutProps {
  path: string;
}

export default SettingsNavigation;
