import { useState, useEffect } from "react";
import { isPlatform } from "@ionic/react";
import { useMutation, useQuery } from "@tanstack/react-query";

import OutboundEventsApi from "../../api/outbound-events";
import { OutboundEventStats, Settings } from "../../interfaces";

import MobileEvents from "./events/mobile";
import DesktopEvents from "./events/desktop";

const isMobile = isPlatform("android") || isPlatform("ios");

const getTotal = (stats: OutboundEventStats[]) => {
  let total = 0;
  stats.forEach((stat) => {
    total += stat.count;
  });
  return total;
};

function Outbound({ settings }: { settings: Settings }) {
  const [openAlert, setOpenAlert] = useState(false);
  const [eventToDisable, setEventToDisable] = useState<string>();
  const [currentLocation, setLocation] = useState<string>();

  const {
    isLoading: isLoadingOutboundEvents,
    data: outboundEventsData,
    refetch: refetchOutboundEvents,
  } = useQuery({
    queryFn: () =>
      OutboundEventsApi.getOutboundEvents({ location: currentLocation }),
    queryKey: ["getOutboundEvents", currentLocation],
    refetchOnWindowFocus: false,
    enabled: settings.isWaReady,
  });

  const {
    isLoading: isLoadingOutboundStatsEvents,
    data: outboundEventsStatsData,
  } = useQuery({
    queryFn: () =>
      OutboundEventsApi.getOutboundEventsStats({ location: currentLocation }),
    queryKey: ["getOutboundEventsStats", currentLocation],
    refetchOnWindowFocus: false,
    enabled: settings.isWaReady,
  });

  const onHideAlert = () => {
    setOpenAlert(() => false);
    return setEventToDisable(undefined);
  };

  const { mutate: mutateUpdateEventStatus, isPending: isUpdatingEvent } =
    useMutation({
      mutationFn: OutboundEventsApi.putOutboundEvent,
      onSuccess: () => {
        refetchOutboundEvents();
        return onHideAlert();
      },
      onError: () => {
        onHideAlert();
      },
    });

  const onDisableEvent = async () => {
    if (eventToDisable) {
      mutateUpdateEventStatus({ id: eventToDisable, active: false });
    }
  };

  const onDisplayAlert = async (active: boolean, id: string) => {
    if (!active) {
      setEventToDisable(() => id);
      return setOpenAlert(() => true);
    }
    return mutateUpdateEventStatus({ id, active: true });
  };

  const onChangeLocation = (locationID: string) => {
    return setLocation(locationID);
  };

  useEffect(() => {
    if (
      outboundEventsData?.locations &&
      outboundEventsData.locations?.length > 1 &&
      !currentLocation
    ) {
      const [firstLocation] = outboundEventsData.locations;
      setLocation(() => firstLocation.id);
    }
  }, [outboundEventsData?.locations, currentLocation]);

  const loading = isLoadingOutboundEvents || isLoadingOutboundStatsEvents;

  const total = getTotal(outboundEventsStatsData || []);

  if (isMobile) {
    return (
      <MobileEvents
        loading={loading}
        total={total}
        currentLocation={currentLocation || ""}
        onChangeLocation={onChangeLocation}
        outbound={outboundEventsData}
        stats={outboundEventsStatsData}
      />
    );
  }

  return (
    <DesktopEvents
      loading={loading}
      isUpdatingEvent={isUpdatingEvent}
      total={total}
      outbound={outboundEventsData}
      stats={outboundEventsStatsData}
      currentLocation={currentLocation || ""}
      onChangeLocation={onChangeLocation}
      openAlert={openAlert}
      onDisplayAlert={onDisplayAlert}
      onHideAlert={onHideAlert}
      onDisableEvent={onDisableEvent}
    />
  );
}

export default Outbound;
