import React, { useEffect, useState } from "react";
import { IonPage, IonContent, IonText } from "@ionic/react";
import { FormattedMessage } from "react-intl";
import "./login.css";
import Auth from "../../api/auth";
import Spinner from "react-bootstrap/Spinner";
import { connect } from "react-redux";
import { deserialize } from "../../utils/serialize";
import { setAuth } from "../../utils/localStorage";
import { setAppUser, setAppAuth } from "../../actions/app";
import styles from "./styles.module.css";
interface ResetForm {
  password: string;
  token: string;
}

const ResetPassword: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [resetError, setResetError] = useState(null);
  const [formData, setFormData] = useState<ResetForm>({
    password: "",
    token: "",
  });

  useEffect(() => {
    const { search } = props.location;
    const { token } = deserialize(search);
    setFormData((prevFormData) => ({
      ...prevFormData,
      token,
    }));
  }, [props.location]);

  const resetApi = async () => {
    setLoading(true);
    setResetError(null);
    const { data, err } = await Auth.resetPassword(formData);
    if (data) {
      const { token, refreshToken, user } = data;
      const { email, company, language, companies } = user;
      await setAuth({ refreshToken, token, email, company });
      await props.dispatch(setAppAuth({ refreshToken, token }));
      await props.dispatch(setAppUser({ email, company, language, companies }));
    }

    if (err) {
      let message = err.message;
      if (err.details && err.details.body && err.details.body.length) {
        err.details.body.forEach((e: Error) => {
          message = message + " " + e.message;
        });
      }
      await setResetError(message);
    }
    await setLoading(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log(formData);
    e.preventDefault();
    await resetApi();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div className={styles.authContainer}>
          <div className={styles.authHolder}>
            <div className={styles.authPanel}>
              <h1>
                <FormattedMessage id="auth.resetPassword.title" />
              </h1>
              <h2>
                <FormattedMessage id="auth.resetPassword.description" />
              </h2>
              <form className={styles.authForm} onSubmit={handleSubmit}>
                <div
                  className={`${styles.inputHolder} ${styles.passwordHolder}`}
                >
                  <label>
                    <FormattedMessage id="auth.resetPassword.password" />
                  </label>
                  <input
                    placeholder="Password"
                    type={show ? "text" : "password"}
                    className="form-control"
                    id="password"
                    value={formData.password}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <span
                    onClick={() => setShow(!show)}
                    className={styles.togglePassword}
                  ></span>
                </div>
                {resetError && (
                  <p>
                    <IonText className="mb-1 color-warning">
                      {resetError}
                    </IonText>
                  </p>
                )}
                <button
                  type="submit"
                  className="btn btn-visito-primary w-100 py-8 mb-4 mt-4 rounded-2"
                >
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">
                        <FormattedMessage id="auth.general.loading" />
                        ...
                      </span>
                    </Spinner>
                  ) : (
                    <FormattedMessage id="auth.resetPassword.submit" />
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  location: Location;
}

export default connect((props: any) => ({}))(ResetPassword);
