import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useIonToast } from "@ionic/react";
import { IconPencil } from "@tabler/icons-react";
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  tablePlugin,
  ListsToggle,
  InsertTable,
  linkPlugin,
  frontmatterPlugin,
  BlockTypeSelect,
  imagePlugin,
} from "@mdxeditor/editor";

import AiApi from "../../../api/ai";

import styles from "./styles.module.css";

type AiContext = {
  limit: number;
  text: string;
  embeddingId?: string;
  isMobile?: boolean;
};

type AiContextProps = AiContext;

function AiTextContext({ limit, text, embeddingId, isMobile = false }: AiContextProps) {
  const [edit, setEdit] = React.useState(false);
  const [context, setContext] = React.useState<AiContext>({
    limit: limit,
    text: text,
  });

  const [present] = useIonToast();

  const intl = useIntl();

  const queryClient = useQueryClient();

  const { mutate: mutateUpdateSettings, isPending: isUpdatingSettings } =
    useMutation({
      mutationFn: AiApi.putAiSettingsByFieldV2,
      onSuccess: () => {
        setEdit(false);
        return present({
          message: intl.formatMessage({ id: "aiUpdateSucces" }),
          duration: 4000,
          color: "light",
          position: "top",
          cssClass: "success-toast",
        });
      },
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 5000,
          color: "light",
          position: "top",
        });
      },
    });

  const onChangeEditor = (content: string) => {
    setContext((prevState) => ({ ...prevState, text: content }));
  };

  const onSave = () => {
    if (context.text.length > context.limit) {
      return present({
        message: intl.formatMessage({ id: "aiExceededLength" }),
        duration: 6000,
        color: "light",
        position: "top",
      });
    }
    return mutateUpdateSettings({ field: "general", value: context.text, embeddingId });
  }

  React.useEffect(() => {
    return () => {
      queryClient.invalidateQueries({
        queryKey: ["getAiSettings"],
      });
    };
  }, []);

  return (
    <div className={styles.editorContainer}>
      <MDXEditor
        markdown={context.text}
        onChange={onChangeEditor}
        readOnly={!edit}
        contentEditableClassName={styles.editorFixedHeight}
        plugins={[
          headingsPlugin(),
          tablePlugin(),
          listsPlugin(),
          quotePlugin(),
          linkPlugin(),
          thematicBreakPlugin(),
          frontmatterPlugin(),
          markdownShortcutPlugin(),
          imagePlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                {!isMobile && (
                  <>
                    <BlockTypeSelect />
                    <div id="styles">
                      <BoldItalicUnderlineToggles />
                    </div>
                    <div id="list">
                      <ListsToggle />
                    </div>
                    <InsertTable />
                  </>
                )}
                {edit ? (
                  <div className="ml-auto flex gap-3 align-items-center">
                    <p className="mb-0">
                      {`${context.text.length} / ${context.limit}`}
                    </p>
                    {isUpdatingSettings && !isMobile && (
                      <p className="mb-0 fw-semibold">
                        <FormattedMessage id="aiSettingsSaving" />
                      </p>
                    )}
                    <button
                      className="btn btn-visito-outline"
                      onClick={() => setEdit(false)}
                      disabled={isUpdatingSettings}
                    >
                      <FormattedMessage id="common.cancel" />
                    </button>
                    <button
                      className="btn btn-visito-primary"
                      onClick={onSave}
                      disabled={isUpdatingSettings}
                    >
                      <FormattedMessage id="common.save" />
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-3 align-items-center">
                    <button
                      className={styles.updateBtn}
                      onClick={() => setEdit(true)}
                    >
                      <IconPencil size={19} />
                      <FormattedMessage id="aiUpdateCta" />
                    </button>
                  </div>
                )}
              </>
            ),
          }),
        ]}
      />
    </div>
  );
}

export default AiTextContext;
