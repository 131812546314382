export const STRIPE = {
  key: process.env.REACT_APP_STRIPE_KEY || null,
  pricingTable: process.env.REACT_APP_PRICING_TABLE || null,
  loginDashboard: process.env.REACT_APP_BILLING_DASHBOARD || null,
};

const config = {
  API_ROOT: process.env.REACT_APP_API_URL || "https://book.visitoai.com",
  HTML_ROOT: process.env.REACT_APP_HTML_BASE || "https://app.visitoai.com",
  CDN_ROOT: "https://s3.visitoai.com",
  GA_ID: process.env.REACT_APP_GA_ID || null,
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID || null,
  VERSION: {
    vName: "1.25-multi-account-integrations-push-header",
    vId: 125,
    vIdentifier: "1.25",
  },
  ENV: process.env.REACT_APP_ENV || "local",
  STRIPE: STRIPE,
};

export default config;
