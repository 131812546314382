import {
  IonHeader,
  IonToolbar,
  isPlatform,
  IonPage,
  IonContent,
  IonTitle,
} from "@ionic/react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// Styles
import headerStyles from "../../theme/header.module.css";

// Other
const isMobile = isPlatform("android") || isPlatform("ios");

const Header = () => {
  return (
    <div>
      <h1 className={headerStyles.pageTitle}>
        <FormattedMessage id="crm.pageTitle" />
      </h1>
      <h3 className={headerStyles.pageDescription}>
        <FormattedMessage id="crm.pageDescription" />
      </h3>
    </div>
  );
};

const MobileHeader = () => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id="crm.pageTitle" />
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="crm.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const MobileTitle = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          <FormattedMessage id="crm.home" />
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

const Crm: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  return (
    <IonPage id="main-content">
      {isMobile && <MobileTitle />}
      <IonContent
        {...(isMobile
          ? { fullscreen: true, color: "light", className: "ion-padding" }
          : {})}
      >
        <div className={isMobile ? "view-wrapper" : "page-wrapper container"}>
          {isMobile ? (
            <>
              <div className="view-pane">
                <MobileHeader />
                <Link to={"/crm/campaigns"}>
                  <FormattedMessage id="crm.campaigns" />
                </Link>
                <br />
                <Link to={"/crm/leads"}>
                  <FormattedMessage id="crm.leads" />
                </Link>
              </div>
            </>
          ) : (
            <>
              <Header />
              <Link to={"/crm/campaigns"}>
                <FormattedMessage id="crm.campaigns" />
              </Link>
              <br />
              <Link to={"/crm/leads"}>
                <FormattedMessage id="crm.leads" />
              </Link>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {}

export default Crm;
