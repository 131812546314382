import { createReducer } from "@reduxjs/toolkit";
import {
  setAppUser,
  setAppAuth,
  setAppLoading,
  cleanAppAuth,
  cleanAppUser,
  setSettings,
  setLanguage,
  setOnboarded,
  setAppUserCompany,
  setAppPushToken,
} from "../actions/app";

import { OnboardingItem, Company } from "../interfaces";
import { integrationList } from "../components/integrations/constants";
const email = localStorage.getItem("email");
const token = localStorage.getItem("token");
const refreshToken = localStorage.getItem("refreshToken");
const companyId = localStorage.getItem("company");

const auth = {
  token: token ? token : "",
  refreshToken: refreshToken ? refreshToken : "",
  pushToken: "",
};

const user = {
  email: email ? email : "",
  company: {
    id: companyId ? companyId : "",
    slug: "",
    website: "",
  },
  companies: [],
  logged: false,
  language: getLang(),
};

const channelKeys = integrationList
  .filter((i) => i.type === "channel")
  .map((c) => c.key);

const locationKeys = integrationList
  .filter((i) => i.type === "location")
  .map((c) => c.key);

function getLang() {
  try {
    const navLang = navigator.language;
    if (navLang) {
      return navLang.split("-")[0];
    }
  } catch {
    return "en";
  }
}

const onboardingList: OnboardingItem[] = [];

const settings = {
  multiAccount: false,
  loading: true,
  badge: 0,
  onboardingList,
  isWaReady: false,
  canSkip: false,
  onboarded: false,
  events: false,
  status: {
    trial: false,
    payment: {
      active: true,
      email: "",
    },
    plan: {
      name: "",
    },
  },
  companies: [],
  company: { name: "", id: "" },
};

const loading = true;

export const App = createReducer(
  { user, auth, loading, settings },
  {
    [setAppUser.type]: (state, action) => ({
      ...state,
      user: {
        ...action.payload,
        language: action.payload.language || getLang(),
        logged: true,
      },
      settings: {
        ...state.settings,
        multiAccount: action.payload.companies?.length > 1,
        company: action.payload.company,
        companies: action.payload.companies
          .map((c: Company) => ({
            name: c.name || "Undefined",
            id: c.id,
          }))
          .sort((a: Company, b: Company) => a.name.localeCompare(b.name)),
      },
    }),
    [setAppUserCompany.type]: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        company: action.payload.company,
        companies: action.payload.companies,
      },
      settings: {
        ...state.settings,
        multiAccount: action.payload.companies?.length > 1,
        company: action.payload.company,
        companies: action.payload.companies
          .map((c: Company) => ({
            name: c.name || "Undefined",
            id: c.id,
          }))
          .sort((a: Company, b: Company) => a.name.localeCompare(b.name)),
      },
    }),
    [setAppAuth.type]: (state, action) => ({
      ...state,
      auth: { ...action.payload },
    }),
    [setAppLoading.type]: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    [cleanAppAuth.type]: (state, action) => ({
      ...state,
      auth: action.payload,
    }),
    [setAppPushToken.type]: (state, action) => ({
      ...state,
      auth: { ...state.auth, pushToken: action.payload },
    }),
    [cleanAppUser.type]: (state, action) => {
      return {
        ...state,
        user: {
          ...user,
        },
      };
    },
    [setLanguage.type]: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        language: action.payload,
      },
    }),

    [setSettings.type]: (state, action) => {
      const onboardingList = [
        { name: "account", done: true },
        { name: "ai", done: action.payload.ai || false },
        {
          name: "location",
          done: locationKeys.map((c) => action.payload[c]).some((l) => l),
        },
        {
          name: "channel",
          done: channelKeys.map((c) => action.payload[c]).some((l) => l),
        },
      ];
      return {
        ...state,
        settings: {
          ...state.settings,
          loading: false,
          onboarded: action.payload.onboarded,
          isWaReady: action.payload.wa || false,
          badge: onboardingList.reduce((a, s) => a + (s.done ? 0 : 1), 0),
          canSkip: onboardingList
            .filter((o) => ["ai", "channel"].includes(o.name))
            .some((o) => o.done),
          onboardingList,
          events: action.payload.events,
          status: {
            ...state.settings.status,
            ...action.payload.status,
          },
        },
      };
    },
    [setOnboarded.type]: (state, action) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          onboarded: action.payload,
        },
      };
    },
  }
);
