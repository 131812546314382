import config from "../config";
import request, { requestOptions } from "../utils/request";

const baseUrl = `${config.API_ROOT}/ai`;

const Ai = {
  getAiSettings() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/settings`;
    return request(url, options);
  },
  getAiSettingsSystem() {
    const options = requestOptions("GET", {}, true);
    const url = `${baseUrl}/settings-system`;
    return request(url, options);
  },
  putAiSettingsByField(field, value) {
    const options = requestOptions("PUT", { value }, true);
    const url = `${baseUrl}/settings/field/${field}`;
    return request(url, options);
  },
  putAiSettingsByFieldV2({ field, value, embeddingId }) {
    const options = requestOptions("PUT", { value, embeddingId }, true);
    const url = `${baseUrl}/settings/field/${field}`;
    return request(url, options);
  },
};

export default Ai;
