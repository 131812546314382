import { createAction } from "@reduxjs/toolkit";
import { Auth, Company } from "../interfaces";

const cleanUser = {
  email: "",
  logged: false,
  language: "es",
};

const cleanAuth = {
  token: "",
  refreshToken: "",
};

export const setAppUser = createAction(
  "SET_APP_USER",
  function prepare(user: {
    email: string;
    company: string;
    language: string;
    companies: Company[];
  }) {
    return {
      payload: user,
    };
  }
);

export const setAppUserCompany = createAction(
  "SET_APP_USER_COMPANY",
  function prepare(user: { company: string; companies: Company[] }) {
    return {
      payload: user,
    };
  }
);

export const setAppAuth = createAction(
  "SET_APP_AUTH",
  function prepare(auth: Auth) {
    return {
      payload: auth,
    };
  }
);

export const setAppPushToken = createAction(
  "SET_APP_PUSH_TOKEN",
  function prepare({ token }: { token: string }) {
    return {
      payload: token,
    };
  }
);

export const setAppLoading = createAction(
  "APP_SET_LOADING",
  function prepare(loading: boolean) {
    return {
      payload: loading,
    };
  }
);

export const cleanAppUser = createAction("CLEAN_APP_USER", function prepare() {
  const user = cleanUser;
  return {
    payload: user,
  };
});

export const cleanAppAuth = createAction("CLEAN_APP_AUTH", function prepare() {
  const auth = cleanAuth;
  return {
    payload: auth,
  };
});

export const setSettings = createAction(
  "APP_SET_SETTINGS",
  function prepare(settings) {
    return {
      payload: settings,
    };
  }
);

export const setLanguage = createAction(
  "APP_SET_LANGUAGE",
  function prepare(language) {
    return {
      payload: language,
    };
  }
);

export const setOnboarded = createAction(
  "APP_SET_ONBOARDED",
  function prepare(value) {
    return {
      payload: value,
    };
  }
);
