import "./ContactDetails.css";
import classNames from "classnames";
import { Booking, Contact } from "../interfaces";
import { FormattedMessage } from "react-intl";

import { parseDate } from "../utils/moment";

interface LayoutProps {
  bookings: Booking[];
  contact: Contact;
  createEscalation: Function;
  solveEscalations: Function;
  toggleBlock: Function;
}

const ContactDetails: React.FC<LayoutProps> = (props) => {
  const { bookings, contact, createEscalation, solveEscalations, toggleBlock } =
    props;

  const bookingsDiv = (
    <>
      <h5 className="color-step-900">
        <FormattedMessage id="contactDetail.bookings" />
      </h5>
      <div className="contact-details-bookings">
        {bookings.map((b) => (
          <div style={{ paddingBottom: "15px" }} key={b.externalId}>
            <div className="booking-id font-14 color-step-800">
              <div>{b.externalId}</div>
              <div>
                <span
                  className={classNames("badge auto-capitalize", {
                    "text-bg-danger": b.status === "cancelled",
                    "text-bg-success": b.status === "confirmed",
                    "text-bg-light": ["checked_out", "checked_in"].includes(
                      b.status
                    ),
                    "text-bg-dark": ![
                      "cancelled",
                      "confirmed",
                      "checked_out",
                      "checked_in",
                    ].includes(b.status),
                  })}
                >
                  {b.status.replace("_", " ")}
                </span>
              </div>
            </div>
            <div className="font-14 color-step-800">{b.locationName}</div>
            {b.rooms?.map((r, k) => (
              <div key={k} className="font-14 color-step-800">
                {r.name}
              </div>
            ))}
            <div className="font-12 color-step-500">
              {parseDate(b.from, "ddd Do, MMM YYYY")}
              <br />
              {parseDate(b.to, "ddd Do, MMM YYYY")}
            </div>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div className="contact-details-container">
      <h5 className="color-step-900">
        <FormattedMessage id="contactDetail.title" />{" "}
      </h5>
      <div className="contact-details-profile">
        <div className="color-step-800 font-14">{contact.name}</div>
        {contact.username && (
          <div className="color-step-800 font-14">@{contact.username}</div>
        )}
        <div className="color-step-500 font-14">
          {contact.type === "ig"
            ? `Instagram`
            : contact.type === "fm"
            ? `Facebook Messenger`
            : contact.type === "web"
            ? `Web`
            : `WhatsApp`}
        </div>
        {contact.type === "wa" ? (
          <div className="color-step-500 font-14">{contact.phoneNumber}</div>
        ) : null}
        {contact.followers ? (
          <div className="color-step-500 font-14">
            <b>{contact.followers}</b>{" "}
            <FormattedMessage id="contactDetail.followers" />
          </div>
        ) : null}
      </div>
      <div className="contact-details-profile">
        <div className="d-grid gap-2">
          <button
            onClick={() =>
              contact.escalated
                ? solveEscalations(contact.id)
                : createEscalation(contact.id)
            }
            className="btn btn-visito-link btn-xs"
          >
            {contact.escalated ? (
              <FormattedMessage id="chats.markAsClose" />
            ) : (
              <FormattedMessage id="chats.markAsOpen" />
            )}
          </button>

          <button
            onClick={() => toggleBlock(contact)}
            className="btn btn-visito-link-danger btn-xs"
          >
            <FormattedMessage
              id={
                contact.blocked ? `chats.unblockContact` : `chats.blockContact`
              }
            />
          </button>
        </div>
      </div>
      {contact.optedOut ? (
        <div className="contact-details-profile">
          <FormattedMessage id="chats.contactOptedOut" />
        </div>
      ) : null}
      {bookings.length ? bookingsDiv : null}
    </div>
  );
};

export default ContactDetails;
