import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonModal,
  IonButton,
  IonButtons,
} from "@ionic/react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { IconFileCheck } from "@tabler/icons-react";

import "./UploadPhotoModal.css";

const UploadPhotoModal: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const {
    show,
    handleClose,
    files,
    upload,
    mode,
    areDocs = false,
    sending,
  } = props;
  const [caption, setCaption] = useState("");

  const onWillDismiss = () => handleClose(false);

  useEffect(() => {
    if (show) {
      setCaption("");
    }
  }, [show]);

  const urls = files.map((file) => URL.createObjectURL(file));

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setCaption(value);
  };

  const onSubmit = () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("caption", caption);
    formData.append("fileType", areDocs ? "document" : "image");
    return upload(formData);
  };

  const content = (
    <div className="upload-photo-modal">
      {!areDocs ? (
        <Carousel showArrows={false} showIndicators={false} showStatus={false}>
          {urls.map((url, i) => {
            const filename = files[i].name;
            return (
              <>
                <img src={url} alt={filename} />
              </>
            );
          })}
        </Carousel>
      ) : (
        <>
          {urls.map((url, i) => {
            const filename = files[i].name;
            return (
              <p className="flex align-items-center gap-2 mb-2">
                <IconFileCheck />
                {filename}
              </p>
            );
          })}
        </>
      )}
      <div className="mt-2 input-holder">
        <div style={{ width: "100%" }}>
          <input
            type="text"
            placeholder="Add a caption..."
            className="form-control"
            id="caption"
            value={caption}
            onChange={(e) => handleChange(e)}
          />
        </div>

        {mode !== "mobile" && (
          <div>
            <button
              className="btn btn-visito-primary"
              type="button"
              onClick={() => onSubmit()}
              disabled={sending}
            >
              {sending ? "Sending..." : "Send"}
            </button>
          </div>
        )}
      </div>
    </div>
  );

  return mode === "desktop" ? (
    <IonModal
      isOpen={show}
      className="upload-modal modal-desktop"
      onWillDismiss={onWillDismiss}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => onWillDismiss()}>
              <FormattedMessage id="common.cancel" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">{content}</IonContent>
    </IonModal>
  ) : (
    <IonModal
      isOpen={show}
      className="upload-modal"
      onWillDismiss={onWillDismiss}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              className="color-visito-2"
              onClick={() => onWillDismiss()}
            >
              Close
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              strong={true}
              className="color-visito-2"
              onClick={() => onSubmit()}
              disabled={sending}
            >
              {sending ? "Sending..." : "Send"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">{content}</IonContent>
    </IonModal>
  );
};

interface ILayoutProps {
  show: boolean;
  mode: string;
  handleClose: Function;
  files: File[];
  upload: Function;
  areDocs?: boolean;
  sending?: boolean;
}

export default UploadPhotoModal;
