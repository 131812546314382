import React from "react";
import { connect } from "react-redux";
import { IonContent, IonPage, useIonToast } from "@ionic/react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { IconRefresh, IconLink } from "@tabler/icons-react";

import AiApi from "../../api/ai";
import PlaygroundApi from "../../api/playground";
import SkeletonText from "../SkeletonText";
import AiTextContext from "./text-context";
import ChatDetail from "../ChatDetail";
import CrawlWebsite from "./website";
import ChatInput from "../common/chat-input";

import { makeSelectContactsDB, makeSelectUser } from "../../selectors";
import { usePlaygroundData } from "../../store/playground";
import { Event } from "../../interfaces";
import { setRequest } from "../../actions/requests";

// import headerStyles from "../../theme/header.module.css";
import styles from "./styles.module.css";

export type UrlWithStatus = {
  url: string;
  completed: boolean;
  error: boolean;
  embeddingId?: string;
  _id?: string;
};

type EmbeddingsInitialState = {
  urls?: UrlWithStatus[];
  progress?: number;
  context?: string;
  isOptimizing?: boolean;
  isScanning?: boolean;
  embeddingId?: string;
  failed?: boolean;
};

function AiSettings({ event, dispatch }: { event: Event; dispatch: Function }) {
  const [content, setContent] = React.useState<string>();
  const [embeddings, setEmbeddings] = React.useState<EmbeddingsInitialState>({
    urls: undefined,
    progress: 0,
    context: undefined,
    isOptimizing: false,
    isScanning: false,
    embeddingId: undefined,
    failed: undefined,
  });

  const intl = useIntl();

  const { company } = useSelector(makeSelectUser());
  const { playground } = usePlaygroundData(company.id);
  const selectedChat = useSelector(
    makeSelectContactsDB(String(playground?.contactId))
  );

  const [present] = useIonToast();

  const {
    data: { data: aiData } = {},
    isLoading: isGettingAi,
    isRefetching: isRefetchingAi,
    refetch: refetchAiData,
  } = useQuery({
    queryFn: () => AiApi.getAiSettings(),
    queryKey: ["getAiSettings"],
    refetchOnWindowFocus: false,
  });

  const {
    data: sendResponse,
    mutate: mutateSendMessage,
    isPending: isSendingMessage,
  } = useMutation({
    mutationFn: PlaygroundApi.sendMessage,
    onSuccess: (data) => {
      if (!data.ok) {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      }
    },
    onError: () => {
      return present({
        message: intl.formatMessage({ id: "common.tryItLater" }),
        duration: 6000,
        color: "light",
        position: "top",
      });
    },
  });

  const { mutate: mutateClearPlayground, isPending: isClearingPlayground } =
    useMutation({
      mutationFn: PlaygroundApi.clearPlayground,
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      },
    });

  const onChangeContent = (newContent: string) => setContent(newContent);

  const onBeforeClearPlayground = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (playground?.contactId) {
      mutateClearPlayground({ contactId: playground.contactId });
    }
  };

  const onSubmit = () => {
    if (content && playground?.contactId) {
      mutateSendMessage({ contactId: playground.contactId, content });
    }
  };

  const onCopyPlayground = async () => {
    await navigator.clipboard.writeText(
      `https://app.visitoai.com/web-chat?slug=${company?.slug}`
    );
    return present({
      message: intl.formatMessage({ id: "common.copyLink" }),
      duration: 6000,
      color: "light",
      position: "top",
    });
  };

  const onHideCrawlingCompleted = () => {
    refetchAiData();
    return setEmbeddings((prevState) => ({ ...prevState, progress: 0 }));
  };

  React.useEffect(() => {
    if (sendResponse && sendResponse?.ok) {
      setContent("");
    }
  }, [sendResponse]);

  React.useEffect(() => {
    if (
      event.type === "company-message" &&
      event.data?.task === "ai-crawling"
    ) {
      const message: UrlWithStatus[] = event.data?.message?.urlsWithStatus;
      const isScanning = event.data?.message?.isScanning || false;
      const isOptimizing = event.data?.message?.isOptimizing || false;
      const completed = event.data?.message?.completed || false;
      const failed = event.data?.message?.failed || false;
      const totalUrls = message.length + 2; // adding two due to last optimization flow
      const totalItems = message.filter((item) => item.completed).length;
      const completedItems = isOptimizing ? totalItems + 1 : totalItems; // adding one due to last optimization flow
      const progress = completed ? 100 : (completedItems / totalUrls) * 100;

      if (failed) {
        present({
          message: intl.formatMessage({ id: "aiCrawlingFailed" }),
          duration: 5800,
          color: "light",
          position: "top",
          cssClass: "error-toast",
        });
      }

      setEmbeddings((prevState) => ({
        ...prevState,
        urls: message,
        progress: progress <= 0 ? 5 : progress,
        isOptimizing,
        isScanning,
        failed,
      }));
    }
  }, [event]);

  React.useEffect(() => {
    if (playground?.contactId) {
      dispatch(setRequest({ contactId: playground.contactId }));
    }
  }, [playground?.contactId]);

  React.useEffect(() => {
    if (aiData?.aiSettings?.embeddings) {
      const oldContext = aiData.aiSettings?.context?.general?.text;

      const { embeddings } = aiData.aiSettings;
      const context = embeddings?.find((emb: any) => emb?.type === "text");

      const totalUrls = embeddings?.length + 1; // adding one due to last optimization flow
      const totalItems = embeddings?.filter(
        (item: any) => item.completed && item.type === "url"
      ).length;
      const progress = (totalItems / totalUrls) * 100;

      setEmbeddings((prevState) => ({
        ...prevState,
        context: oldContext || context?.embededContent,
        isOptimizing: totalUrls - 1 === totalItems,
        progress: progress <= 0 ? 5 : progress,
        urls: embeddings?.filter((emb: any) => emb?.type === "url"),
        embeddingId: context?._id,
      }));
    }
  }, [aiData]);

  const missingAiContext =
    !embeddings?.context && !isGettingAi && !isRefetchingAi;

  return (
    <IonPage id="main-content">
      <IonContent>
        <div className="page-wrapper">
          {isGettingAi || isRefetchingAi ? (
            <div className="h-100 w-100 flex flex-column p-4">
              <SkeletonText rows={17} />
            </div>
          ) : (
            <div className={styles.playgroundWrapper}>
              <div
                className={`${styles.contextManagementWrapper} ${
                  missingAiContext ? "w-100" : ""
                }`}
              >
                {/*
                <div className="mb-2">
                  <h1 className={`${headerStyles.pageTitle} pt-0 mb-0 pb-0`}>
                    <FormattedMessage id="aiSettingsKnowledge" />
                  </h1>
                  <h3 className={`${headerStyles.pageDescription} mb-1`}>
                    <FormattedMessage id="aiSettingsAlert" />
                  </h3>
                </div>
                */}

                {missingAiContext ? (
                  <CrawlWebsite
                    company={{
                      id: company.id,
                      website: company.website,
                    }}
                    urls={embeddings?.urls}
                    progress={embeddings?.progress}
                    isOptimizing={embeddings?.isOptimizing}
                    isScanning={embeddings?.isScanning}
                    onHideCrawlingCompleted={onHideCrawlingCompleted}
                    setScanning={() =>
                      setEmbeddings((prevState) => ({
                        ...prevState,
                        isScanning: true,
                      }))
                    }
                  />
                ) : (
                  <AiTextContext
                    limit={aiData?.aiSettings?.context?.general?.limit || 0}
                    text={embeddings?.context || ""}
                    embeddingId={embeddings?.embeddingId}
                  />
                )}
              </div>

              {!missingAiContext && (
                <>
                  {!selectedChat.contact.id ||
                  selectedChat.contact.id.length === 0 ? (
                    <div className={styles.chatWrapper}>
                      <div className={styles.chatSkeleton}>
                        <SkeletonText rows={15} />
                      </div>
                    </div>
                  ) : (
                    <div className={styles.chatWrapper}>
                      <div className={styles.chatContainer}>
                        <div className={styles.chatHeaderContainer}>
                          <div className={styles.chatHeaderContent}>
                            <div className="flex gap-3 align-items-center">
                              <h3 className="fs-4 mb-0">
                                <FormattedMessage id="aiAgent" />
                              </h3>
                            </div>
                            <div className="flex gap-2 align-items-center">
                              <button
                                className={`${styles.resetBtn} p-1 px-2 fs-2 gap-1`}
                                onClick={onCopyPlayground}
                              >
                                <IconLink size={17} />
                                <FormattedMessage id="common.share" />
                              </button>
                              <button
                                className={styles.resetBtn}
                                onClick={onBeforeClearPlayground}
                                disabled={isClearingPlayground}
                              >
                                <IconRefresh size={17} />
                                <FormattedMessage id="aiSettingsReset" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="h-100 w-100">
                          <ChatDetail
                            messages={selectedChat.messages}
                            openImage={(link) =>
                              console.log("Open image", link)
                            }
                            contactId={selectedChat.contact.id}
                            mode={"desktop"}
                            scrollEscalations={1}
                            type="playground"
                            companySlug={company?.slug}
                          />
                        </div>
                        <div
                          className={`${styles.chatInputContainer} ${
                            selectedChat.messages.length === 0 ? "pt-3" : ""
                          }`}
                        >
                          <ChatInput
                            id="send-body-playground"
                            value={content}
                            placeholder={intl.formatMessage({
                              id: "chats.newMessageInput",
                            })}
                            enableWaActions={false}
                            onInputChange={onChangeContent}
                            onBeforeSubmit={onSubmit}
                            disabled={false /* isSendingMessage */}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
}

export default connect((props: any) => ({}))(AiSettings);
