import config from "../config";
import { Campaign, Lead, Pagination } from "../interfaces";
import { adaptCampaigns, adaptLeads } from "../utils/crm";
import request, {
  requestOptions,
  requestOptionsFormData,
} from "../utils/request";
import { ErrorWithLocale, GlobalError } from "../interfaces";

const baseUrl = `${config.API_ROOT}/app/crm`;

type serverResponse = {
  ok: boolean;
  error?: ErrorWithLocale;
};

const Crm = {
  async getCampaigns({
    limit = null,
    page = null,
  }: {
    limit?: null | number;
    page?: null | number;
  }) {
    const options = requestOptions("GET", {}, true);
    const url = new URL(`${baseUrl}/campaigns`);

    if (limit) {
      url.searchParams.set("limit", String(limit));
    }
    if (page) {
      url.searchParams.set("page", String(page));
    }

    const { data } = await request(url.toString(), options);
    const { campaigns, pagination } = data;

    if (!campaigns) {
      throw new Error("There was an error getting the list of campaigns");
    }

    const adaptedData = adaptCampaigns(campaigns) as Campaign[];

    return { campaigns: adaptedData, pagination: pagination as Pagination };
  },
  async getLeads({
    limit = null,
    page = null,
  }: {
    limit?: null | number;
    page?: null | number;
  }) {
    const options = requestOptions("GET", {}, true);
    const url = new URL(`${baseUrl}/leads`);

    if (limit) {
      url.searchParams.set("limit", String(limit));
    }
    if (page) {
      url.searchParams.set("page", String(page));
    }

    const { data } = await request(url.toString(), options);
    const { leads, pagination } = data;

    if (!leads) {
      throw new Error("There was an error getting the list of leads events");
    }

    const adaptedData = adaptLeads(leads) as Lead[];

    return { leads: adaptedData, pagination: pagination as Pagination };
  },
  async createCampaign({ name, template }: { name: string; template: string }) {
    const options = requestOptions("POST", { template, name }, true);
    const url = new URL(`${baseUrl}/campaigns`);

    const { data, err }: { data: serverResponse; err: GlobalError } =
      await request(url.toString(), options);

    console.log(data, err);

    if (err) {
      throw err;
    }

    return data;
  },

  downloadLeads: async () => {
    const options = requestOptions("POST", {}, true);
    const url = new URL(`${baseUrl}/leads/download`);
    const { data, err } = await request(url.toString(), options, "blob");
    return { data, err };
  },

  uploadLeads: async (formData: FormData) => {
    const options = requestOptionsFormData(formData, true);
    const url = new URL(`${baseUrl}/leads/upload`);
    const { data, err } = await request(url.toString(), options);
    return { data, err };
  },
};

export default Crm;
