import { useState, lazy, Suspense } from "react";
import {
  IonHeader,
  IonToolbar,
  isPlatform,
  IonPage,
  IonContent,
  IonTitle,
  IonBreadcrumbs,
  IonBreadcrumb,
} from "@ionic/react";

import { FormattedMessage } from "react-intl";
import { useQuery } from "@tanstack/react-query";

// Components
import PaginationComponent from "../../common/pagination";
import Campaigns from "./list";

// Styles
import headerStyles from "../../../theme/header.module.css";
import styles from "./styles.module.css";

// API
import CrmApi from "../../../api/crm";

// Other
const isMobile = isPlatform("android") || isPlatform("ios");
const NewCampaign = lazy(() => import("./new"));

const Header = () => {
  return (
    <div>
      <h1 className={headerStyles.pageTitle}>
        <FormattedMessage id="campaigns.pageTitle" />
      </h1>
      <h3 className={headerStyles.pageDescription}>
        <FormattedMessage id="campaigns.pageDescription" />
      </h3>
    </div>
  );
};

const MobileHeader = () => {
  return (
    <div>
      <div className={headerStyles.header}>
        <h1 className={headerStyles.mobileTitle}>
          <FormattedMessage id="campaigns.pageTitle" />
        </h1>
        <h3 className={headerStyles.mobileDescription}>
          <FormattedMessage id="campaigns.pageDescription" />
        </h3>
      </div>
    </div>
  );
};

const MobileTitle = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>
          <FormattedMessage id="campaigns.home" />
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

const CrmCampaigns: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isOpenNewCampaign, setOpenNewCampaign] = useState<boolean>(false);

  const {
    isLoading: isLoadingCampaigns,
    data: campaignsData,
    refetch: refetchCampaigns,
  } = useQuery({
    queryFn: () => CrmApi.getCampaigns({ page: currentPage }),
    queryKey: ["getCrmCampaigns", currentPage],
    refetchOnWindowFocus: false,
  });

  const onCreateSucess = () => {
    console.log("create success");
    refetchCampaigns();
  };

  console.log(isLoadingCampaigns, campaignsData);

  return (
    <IonPage id="main-content">
      {isMobile && <MobileTitle />}
      <IonContent
        {...(isMobile
          ? { fullscreen: true, color: "light", className: "ion-padding" }
          : {})}
      >
        <div className={isMobile ? "view-wrapper" : "page-wrapper container"}>
          {isMobile ? (
            <>
              <div className="view-pane">
                <MobileHeader />

                <Campaigns
                  isMobile={isMobile}
                  loading={isLoadingCampaigns}
                  list={campaignsData?.campaigns}
                />
              </div>
            </>
          ) : (
            <div className="">
              <div className={styles.breadcrumbContainer}>
                <IonBreadcrumbs mode="ios">
                  <IonBreadcrumb
                    routerLink="/crm"
                    className="breadcrumb-parent"
                  >
                    <FormattedMessage id="nav.crm" />
                  </IonBreadcrumb>
                  <IonBreadcrumb routerLink={`/outbound`}>
                    <FormattedMessage id="crm.campaigns" />
                  </IonBreadcrumb>
                </IonBreadcrumbs>
              </div>
              <Header />

              <div className="mb-4">
                <button
                  className="btn btn-visito-primary"
                  onClick={() => setOpenNewCampaign(true)}
                >
                  <FormattedMessage id="campaigns.newCampaign" />
                </button>
                <Suspense fallback="Loading...">
                  <NewCampaign
                    isOpen={isOpenNewCampaign}
                    onOpenChange={setOpenNewCampaign}
                    onSuccess={() => onCreateSucess()}
                  />
                </Suspense>
              </div>

              <Campaigns
                isMobile={isMobile}
                loading={isLoadingCampaigns}
                list={campaignsData?.campaigns}
              />

              {campaignsData && campaignsData?.pagination?.totalPages > 1 && (
                <PaginationComponent
                  currentPage={campaignsData?.pagination.currentPage}
                  totalPages={campaignsData?.pagination?.totalPages}
                  onChangePage={(page) => setCurrentPage(page)}
                />
              )}
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {}

export default CrmCampaigns;
