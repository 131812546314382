import { createSelector } from "reselect";
import type { RootState } from "./store";
import { ContactsDBItem } from "./interfaces";

export const makeSelectContactsDB = (id: string) =>
  createSelector(
    (state: RootState) => state.contacts,
    (contacts) => {
      if (contacts.contactsDbObject.hasOwnProperty(id)) {
        const item = contacts.contactsDbObject[id];
        return item;
      }
      const empty: ContactsDBItem = {
        contact: {
          type: "list",
          id: "",
          name: "",
          preview: "",
          unread: 0,
          timestamp: "",
          enabled: false,
          optedOut: false,
          manual: false,
          blocked: false,
          phoneNumber: "",
          status: "",
          escalated: false,
          isBook: false,
          isManual: false,
          timestampNative: new Date().getTime(),
          count: 0,
          followers: 0,
          username: "",
          companies: [],
        },
        messages: [],
        bookings: [],
        escalations: [],
      };
      return empty;
    }
  );

export const makeSelectSettings = () =>
  createSelector(
    (state: RootState) => state.app,
    (app) => app.settings
  );

export const makeSelectUser = () =>
  createSelector(
    (state: RootState) => state.app,
    (app) => app.user
  );
