import { FormattedMessage } from "react-intl";
import { IconCircleCheckFilled, IconCircleXFilled } from "@tabler/icons-react";

import styles from "./styles.module.css";
import badgesStyles from "../../../theme/badges.module.css";

const MessageStatus = ({ type }: { type: string }) => (
  <div className={styles.badgeContainer}>
    <div
      className={`${styles.dot} ${
        type === "error" || type === "failed" ? styles.danger : styles.success
      }`}
    />
    <FormattedMessage
      id={
        type === "error" || type === "failed"
          ? "common.error"
          : "common.success"
      }
    />
  </div>
);

export const EventStatus = ({ active }: { active: boolean }) => (
  <div className={badgesStyles.statusBadge}>
    {active ? (
      <IconCircleCheckFilled className={badgesStyles.activeIcon} size={16} />
    ) : (
      <IconCircleXFilled className={badgesStyles.inactiveIcon} size={16} />
    )}
    <FormattedMessage id={active ? "common.active" : "common.inactive"} />
  </div>
);

export default MessageStatus;
