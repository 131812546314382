import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// Styles
import styles from "./styles.module.css";
import tableStyles from "./../../../theme/table.module.css";

// Interfaces
import { Lead } from "../../../interfaces";

// Components
import SkeletonText from "../../SkeletonText";

const Campaigns = ({ isMobile, loading, list }: ILayoutProps) => {
  console.log({ isMobile, loading, list });
  return isMobile ? (
    loading ? (
      <div>
        <SkeletonText rows={6} />
      </div>
    ) : (
      <div>
        <div className="fw-semibold mb-3 mt-3">
          <FormattedMessage id="crm.campaignsListTitle" />
        </div>
      </div>
    )
  ) : loading ? (
    <div>
      <SkeletonText rows={12} />{" "}
    </div>
  ) : (
    <div className="row">
      <div className="col-md-12">
        <div className={tableStyles.tableContainer}>
          <table className="table mb-0">
            <thead className={tableStyles.theadFixed}>
              <tr className={tableStyles.tableHeader}>
                <th className={tableStyles.tableTitle}>
                  <FormattedMessage id="leads.creationDate" />
                </th>
                <th className={tableStyles.tableTitle}>
                  <FormattedMessage id="leads.name" />
                </th>
                <th className={tableStyles.tableTitle}>
                  <FormattedMessage id="leads.country" />
                </th>
                <th className={tableStyles.tableTitle}>
                  <FormattedMessage id="leads.phoneNumber" />
                </th>
              </tr>
            </thead>
            <tbody className={tableStyles.tableBody}>
              {list && list.length === 0 && (
                <tr className="fs-3">
                  <td className="text-center" colSpan={3}>
                    <FormattedMessage id="common.noResults" />
                  </td>
                </tr>
              )}
              {list &&
                list.map((o) => (
                  <tr key={o.id}>
                    <td
                      className={`${tableStyles.tdFullWidth} ${tableStyles.commonTd}`}
                    >
                      {o.timestamp}
                    </td>
                    <td className={tableStyles.commonTd}>
                      <Link to={`/crm/campaigns/${o.id}`}>{o.name}</Link>
                    </td>
                    <td className={tableStyles.commonTd}>{o.country}</td>
                    <td className={tableStyles.commonTd}>{o.phoneNumber}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

interface ILayoutProps {
  isMobile: boolean;
  loading: boolean;
  list: Lead[] | undefined;
}

export default Campaigns;
