import { IonPage, IonContent, IonToggle } from "@ionic/react";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { OutboundEvent, OutboundEventStats } from "../../../interfaces";
import SkeletonLoader from "../../../components/SkeletonLoader";
import SkeletonText from "../../../components/SkeletonText";

// Api
import AiApi from "../../../api/ai";

interface Tools {
  id: string;
  name: string;
  description: string;
  active: boolean;
  instructions: {
    preSystem: string;
    posSystem: string;
  };
}

interface System {
  who: string;
  extra: string;
  behaviour: string;
  objectives: string;
  inablity: string;
}

interface SystemSetting {
  industry: string;
  system: System;
  tools: Tools[];
}

const AiSettingsSystem: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const [loading, setLoading] = useState(true);

  const [system, setSystem] = useState<SystemSetting>({
    industry: "",
    system: { extra: "", behaviour: "", objectives: "", inablity: "", who: "" },
    tools: [],
  });

  useEffect(() => {
    const requestAiSettingsSystem = async () => {
      setLoading(true);
      const { data, err } = await AiApi.getAiSettingsSystem();
      if (data) {
        setSystem(data.aiSettingsSystem);
      }
      if (err) {
        console.log(err);
      }
      setLoading(false);
    };
    requestAiSettingsSystem();
  }, []);

  const handleActiveChange = async (
    event: CustomEvent<{ checked: boolean }>,
    value: { toolId: string }
  ) => {
    setSystem((prevSystem) => ({
      ...prevSystem,
      tools: prevSystem.tools.map((tool) => {
        if (tool.id === value.toolId) {
          return { ...tool, active: event.detail.checked };
        }
        return tool;
      }),
    }));

    await AiApi.putAiSettingsByField("tool_toggle", {
      active: event.detail.checked,
      toolId: value.toolId,
    });
  };

  const handleTextChange = async (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { value, id } = e.currentTarget;
    const [type, toolId] = id.split("_");

    switch (id) {
      case "who":
      case "extra":
      case "behaviour":
      case "objectives":
      case "inablity":
        setSystem((prevSystem) => ({
          ...prevSystem,
          system: {
            ...prevSystem.system,
            [id]: value,
          },
        }));
        break;
      default:
        setSystem((prevSystem) => ({
          ...prevSystem,
          tools: prevSystem.tools.map((tool) => {
            if (tool.id === toolId) {
              const updatedInstructions = {
                ...tool.instructions,
                [`${type}System`]: value,
              };
              return { ...tool, instructions: updatedInstructions };
            }
            return tool;
          }),
        }));
        break;
    }
  };

  const submitChange = async (type: string, value: string | object) => {
    const { err } = await AiApi.putAiSettingsByField(type, value);
    console.log(err);
  };

  const extra = (
    <div className="visito-card visito-block mb-3">
      <div className="visito-card-header">
        <div className="visito-card-title">Extend assistant behaviour</div>
      </div>
      <div className="visito-card-body">
        <div className="visito-card-list">
          {loading ? (
            <SkeletonText rows={2} />
          ) : (
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label>Extend intructions for who the assitant is</label>
                  <textarea
                    id="who"
                    className="form-control"
                    value={system.system.who}
                    rows={2}
                    onChange={(e) => handleTextChange(e)}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label>
                    Extend intructions for general assistant behaviour
                  </label>
                  <textarea
                    id="behaviour"
                    className="form-control"
                    value={system.system.behaviour}
                    rows={2}
                    onChange={(e) => handleTextChange(e)}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label>
                    Extend intructions for what are the assistant objectives
                    during the conversation
                  </label>
                  <textarea
                    id="objectives"
                    className="form-control"
                    value={system.system.objectives}
                    rows={2}
                    onChange={(e) => handleTextChange(e)}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label>
                    Extend intructions for what assistant cannot do during the
                    conversation (inabilities)
                  </label>
                  <textarea
                    id="inablity"
                    className="form-control"
                    value={system.system.inablity}
                    rows={2}
                    onChange={(e) => handleTextChange(e)}
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="form-group">
                  <label>
                    Extend extra not so relevant considerations for the
                    assitant.
                  </label>
                  <textarea
                    id="extra"
                    className="form-control"
                    value={system.system.extra}
                    rows={2}
                    onChange={(e) => handleTextChange(e)}
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row mt-3">
          <div className="col-md-12" style={{ textAlign: "right" }}>
            <button
              className="btn btn-visito-primary btn-sm"
              onClick={() => submitChange("system", system.system)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const tools = system.tools.map((t) => (
    <div className="visito-card visito-block mb-3" key={t.id}>
      <div className="visito-card-header">
        <div className="visito-card-title">Tool: {t.name}</div>
        <IonToggle
          checked={t.active}
          onIonChange={(e) => handleActiveChange(e, { toolId: t.id })}
          mode="ios"
          className="ios visito-toggle"
        />
      </div>
      <div className="visito-card-body">
        <div className="visito-card-list">
          {loading ? (
            <SkeletonText rows={2} />
          ) : (
            <>
              <div>
                <p>{t.description}</p>
              </div>
            </>
          )}
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <textarea
              placeholder="Extend behaviour for calling this function."
              className="form-control"
              rows={2}
              value={t.instructions.preSystem}
              id={"pre_" + t.id}
              onChange={(e) => handleTextChange(e)}
            ></textarea>
          </div>
          <div className="col-md-12">
            <textarea
              placeholder="Extend behaviour for responding to this function."
              className="form-control"
              rows={2}
              value={t.instructions.posSystem}
              id={"pos_" + t.id}
              onChange={(e) => handleTextChange(e)}
            ></textarea>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12" style={{ textAlign: "right" }}>
            <button
              className="btn btn-visito-primary btn-sm"
              onClick={() =>
                submitChange("tool_instructions", {
                  instructions: t.instructions,
                  toolId: t.id,
                })
              }
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <IonPage id="main-content">
      <IonContent>
        <div className="page-wrapper container">
          <h1 className="page-title">
            System Configuration ({system.industry})
          </h1>
          <div className="row">
            <div className="col-md-12">
              {loading ? (
                <SkeletonLoader rows={1} />
              ) : (
                <>
                  {extra} {tools}
                </>
              )}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  outbound: {
    outboundEvents: OutboundEvent[];
    outboundEventStats: OutboundEventStats[];
  };
}

export default connect((props: any) => ({
  outbound: props.outboundEvents,
}))(AiSettingsSystem);
