export const loadScript = (src: string, callback: Function) => {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.onload = () => {
    console.log(`${src} has loaded`);
    if (callback) callback();
  };
  script.onerror = () => {
    console.error(`Error loading ${src}`);
  };
  document.body.appendChild(script);
};

export default loadScript;
