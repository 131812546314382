import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
  IonButtons,
  IonBackButton,
} from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";
import { IconRefresh, IconLink } from "@tabler/icons-react";
import { Clipboard } from "@capacitor/clipboard";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";

import ChatDetail from "../../ChatDetail";
import ChatInput from "../../common/chat-input";
import { makeSelectContactsDB, makeSelectUser } from "../../../selectors";
import { usePlaygroundData } from "../../../store/playground";
import PlaygroundApi from "../../../api/playground";

import styles from "../styles.module.css";
import mobileStyles from "./styles.module.css";
import { setRequest } from "../../../actions/requests";

function MobileAi({ dispatch }: { dispatch: Function }) {
  const [content, setContent] = React.useState<string>();
  const [isFocus, setIsFocus] = React.useState(0);

  const intl = useIntl();

  const { company } = useSelector(makeSelectUser());

  const { playground } = usePlaygroundData(company.id);

  const selectedChat = useSelector(
    makeSelectContactsDB(String(playground?.contactId))
  );

  const [present] = useIonToast();

  const {
    data: sendResponse,
    mutate: mutateSendMessage,
    //isPending: isSendingMessage,
  } = useMutation({
    mutationFn: PlaygroundApi.sendMessage,
    onSuccess: (data) => {
      if (!data.ok) {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      }
    },
    onError: () => {
      return present({
        message: intl.formatMessage({ id: "common.tryItLater" }),
        duration: 6000,
        color: "light",
        position: "top",
      });
    },
  });

  const { mutate: mutateClearPlayground, isPending: isClearingPlayground } =
    useMutation({
      mutationFn: PlaygroundApi.clearPlayground,
      onError: () => {
        return present({
          message: intl.formatMessage({ id: "common.tryItLater" }),
          duration: 6000,
          color: "light",
          position: "top",
        });
      },
    });

  const onSubmit = () => {
    if (content && playground?.contactId) {
      mutateSendMessage({ contactId: playground.contactId, content });
    }
  };

  const onCopyPlayground = async () => {
    await Clipboard.write({
      url: `https://app.visitoai.com/web-chat?slug=${company?.slug}`,
    });
    return present({
      message: intl.formatMessage({ id: "common.copyLink" }),
      duration: 6000,
      color: "light",
      position: "top",
    });
  };

  const onChangeContent = (newContent: string) => setContent(newContent);

  const onBeforeClearPlayground = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (playground?.contactId) {
      mutateClearPlayground({ contactId: playground.contactId });
    }
  };

  React.useEffect(() => {
    if (sendResponse && sendResponse?.ok) {
      setContent("");
    }
  }, [sendResponse]);

  React.useEffect(() => {
    if (playground?.contactId) {
      dispatch(setRequest({ contactId: playground.contactId }));
    }
  }, [playground?.contactId]);

  const inputFocused = () => setTimeout(() => setIsFocus(isFocus + 1), 650);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={`/settings`} />
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="common.virtualAssistant" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" className="ion-padding h-100">
        <div className="view-wrapper h-100">
          <div className="view-pane-body pt-0 h-100">
            <div className={mobileStyles.mobileChatContainer}>
              <div className={mobileStyles.mobileChatDetailWrapper}>
                <div className={styles.chatDetailContainer}>
                  {selectedChat.messages.length > 0 && (
                    <div className={mobileStyles.mobileChatHeaderContent}>
                      <div className="flex gap-3 align-items-center">
                        <button
                          className={`${styles.resetBtn} p-1 px-2 fs-2 gap-1`}
                          onClick={onCopyPlayground}
                        >
                          <IconLink size={17} />
                          <FormattedMessage id="common.share" />
                        </button>
                      </div>
                      <button
                        className={`${styles.resetBtn}`}
                        onClick={onBeforeClearPlayground}
                        disabled={isClearingPlayground}
                      >
                        <IconRefresh size={17} />
                        <FormattedMessage id="aiSettingsReset" />
                      </button>
                    </div>
                  )}
                  <div className="h-100 w-100">
                    <ChatDetail
                      messages={selectedChat.messages}
                      openImage={(link) => console.log("Open image", link)}
                      contactId={selectedChat.contact.id}
                      mode="ios"
                      scrollEscalations={1}
                      type="playground"
                      scroll={isFocus}
                      companySlug={company?.slug}
                    />
                  </div>
                </div>
                <div
                  className={`${mobileStyles.mobileChatInputContainer} ${
                    selectedChat.messages.length === 0 ? "pt-3" : ""
                  }`}
                >
                  <ChatInput
                    id="send-body-playground"
                    value={content}
                    placeholder={intl.formatMessage({
                      id: "chats.newMessageInput",
                    })}
                    enableWaActions={false}
                    onInputChange={onChangeContent}
                    onBeforeSubmit={onSubmit}
                    onAfterFocus={inputFocused}
                    //disabled={isSendingMessage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default connect((props: any) => ({}))(MobileAi);
