import { FormattedMessage } from "react-intl";
import styles from "../styles.module.css";

import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonTitle,
  IonModal,
  IonButtons,
  IonButton,
} from "@ionic/react";

interface Properties {
  isOpen: boolean;
  onWillDismiss: () => void;
  create: Function;
  loading: boolean;
  error: string;
  isMobile: boolean;
}

const NewCloudbedsConnection = ({
  isOpen,
  onWillDismiss,
  create,
  loading,
  error,
  isMobile,
}: Properties) => {
  return (
    <IonModal isOpen={isOpen} onWillDismiss={onWillDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <IonTitle>
              {isMobile ? (
                <FormattedMessage id={`integrations.cbName`} />
              ) : (
                <div className={styles.titleDisplay}>
                  <div className={`${styles.titleItem} ${styles.logo}`}>
                    <div className={`${styles.logo} cloudbeds-logo`}></div>
                  </div>

                  <h3 className="fs-3">
                    <FormattedMessage id={`integrations.cbName`} />
                  </h3>
                </div>
              )}
            </IonTitle>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onWillDismiss()}>
              <FormattedMessage id="common.cancel" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={styles.modalContent}>
          <h3 className="fs-3">
            <FormattedMessage id="integrations.acceptCloudbedsNewIntegration" />
          </h3>

          <button
            className="btn btn-visito-primary btn-sm"
            color="success"
            onClick={() => create()}
            disabled={loading}
          >
            {loading ? (
              <>
                <FormattedMessage id="common.loading" />
                {`...`}
              </>
            ) : (
              <FormattedMessage id="common.accept" />
            )}
          </button>
          {error && (
            <div className="color-danger fw-semibold-1 fs-2 mt-2">{error}</div>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default NewCloudbedsConnection;
