import { connect } from "react-redux";

import { IonPage, IonContent } from "@ionic/react";

import { Switch } from "react-router-dom";
import { Route } from "react-router";
// Pages
import Language from "./settings/Language";
import Integrations from "../../../components/integrations";
import Team from "./settings/Team";
import Payment from "./settings/Payment";

// User
import { User, Settings } from "../../../interfaces";

// Components
import SettingsNavigation from "../../../components/SettingsNavigation";
import "./Settings.css";

const SettingsComponent: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { path } = props;
  return (
    <IonPage id="main-content">
      <IonContent>
        <div className="page-wrapper">
          <div className="settings-wrapper">
            <div className="menu-holder">
              <SettingsNavigation path={path} />
            </div>
            <div className="content-holder flex-center">
              <Switch>
                <Route
                  exact
                  path="/settings"
                  render={() => <Team user={props.user} />}
                />
                <Route
                  exact
                  path="/settings/language"
                  render={() => <Language user={props.user} />}
                />
                <Route
                  exact
                  path="/settings/payment"
                  render={() => (
                    <Payment
                      user={props.user}
                      settings={props.settings}
                      onRequestSettings={props.onRequestSettings}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

interface ILayoutProps {
  dispatch: Function;
  user: User;
  path: string;
  settings: Settings;
  onRequestSettings: Function;
}

export default connect((props: any) => ({}))(SettingsComponent);
